import { CustomLink as Link } from '@/components/custom-link';
import Icon from '@/components/data-display/icon';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import NextImage from '@/components/data-display/image';
import Button from '@/components/inputs/button';
import { SIZE } from '@/components/inputs/button/button.types';
import Nav from '@/components/navigation/nav';
import { useClientSideMarketContext } from '@/context/client-side-market/market-context';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import useBasePath from '@/hooks/use-base-path';
import useSearch from '@/hooks/use-search';
import MemberDetails from '@/modules/member-details';
import { useUser } from '@/providers/auth0';
import { DATA_IDS } from '@/types';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME, USER_ACTION } from '@/utils/track-event/track-event.types';
import { PRODUCT_CODES } from '@channel/shared-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { isAviosOpco } from '@/utils/opco-utils';
import styles from './header.module.scss';
import type { HeaderProperties } from './header.types';

const SharedHeader = dynamic(
  async () => {
    const sharedComponentsModules = await import('@channel/shared-components');
    return sharedComponentsModules.Header;
  },
  {
    ssr: false,
  },
);

const HeaderAvios = dynamic(
  async () => {
    const shared = await import('@channel/shared-header-footer');
    return shared.Header;
  },
  {
    ssr: false,
  },
);

const Header = ({
  logo,
  smallLogo,
  membershipLabel,
  navigation,
  myAccount,
  membershipDetails,
  children: headerChildren,
  loginLabel,
  sharedHeaderProps,
}: HeaderProperties) => {
  const { asPath: currentUrl, query, push } = useRouter();
  const featureToggles = useFeatureTogglesContext();
  const { opCoId } = useClientSideMarketContext();
  const isAvios = isAviosOpco(opCoId);
  const sharedHeaderEnabled = featureToggles.includes(
    FEATURE_TOGGLE_LIST.TEMP_SHARED_HEADER,
  );
  const disabledCLO = !featureToggles.includes(FEATURE_TOGGLE_LIST.CLO);
  const { basePath, pathPrefix } = useBasePath();

  const reference = useRef<HTMLDivElement>(null);
  const [showMemberDetails, setShowMemberDetails] = useState<boolean>(false);

  const handleOnMouseEnter = useCallback(() => setShowMemberDetails(true), []);
  const handleOnClick = useCallback(
    () => setShowMemberDetails(!showMemberDetails),
    [showMemberDetails],
  );
  const handleClose = useCallback(() => setShowMemberDetails(false), []);

  useClickAway(reference, () => setShowMemberDetails(false));

  const { searchResults, searchValue, setSearchValue } = useSearch(
    null,
    query?.search as string,
    disabledCLO,
  );

  const { user: currentUser, isLoading } = useUser();
  const { searchBar, channelsClubUrl, ...sharedHeaderPropertiesRest } =
    sharedHeaderProps;

  if (isAvios)
    return (
      <div className={styles['avios-header']}>
        <HeaderAvios
          auth={{
            loginProps: {
              href: `${pathPrefix}/api/auth/login/?returnTo=${encodeURIComponent(
                currentUrl,
              )}`,
            },
            logoutProps: { href: `${pathPrefix}/api/auth/logout/` },
          }}
          userDetails={
            currentUser
              ? {
                  aviosBalance: Number(membershipDetails.aviosBalance),
                  membershipNumber: currentUser?.org_id
                    ? Number(currentUser?.org_id)
                    : 0,
                  name: currentUser?.name ?? '',
                }
              : undefined
          }
        />
      </div>
    );

  if (sharedHeaderEnabled) {
    return (
      <SharedHeader
        key={currentUrl}
        {...sharedHeaderPropertiesRest}
        search={{
          enabled: true,
          merchants: searchResults,
          value: searchValue,
          onChange(event) {
            setSearchValue(event.currentTarget.value);
          },
          onSubmitClick: () => {
            const retailersPathname = `${basePath}/retailers/`;
            const newQuery = {
              ...(searchValue && { search: encodeURIComponent(searchValue) }),
            };
            push(
              {
                query: newQuery,
              },
              { pathname: retailersPathname, query: newQuery },
              undefined,
            );
          },
        }}
        user={currentUser}
        balance={Number(membershipDetails.aviosBalance)}
        auth={{
          loginProps: {
            href: `${pathPrefix}/api/auth/login/?returnTo=${encodeURIComponent(
              currentUrl,
            )}`,
          },
          logoutProps: {
            href: `${pathPrefix}/api/auth/logout/`,
          },
        }}
        currentPath={currentUrl}
        routerOptions={{
          as: Link,
          usesTo: false,
          product: PRODUCT_CODES.SHOPPING,
        }}
        baseUrl={channelsClubUrl}
      />
    );
  }

  return (
    <header className={styles.header}>
      <div className={styles['header__primary-container']}>
        <div className={styles.header__primary}>
          <Link
            href={logo.href}
            className={styles.header__logo}
            data-id={DATA_IDS.HEADER_LOGO}
            title={logo.alt}
            aria-label={logo.alt}
          >
            <NextImage src={logo.src} alt={logo.alt} fill />
          </Link>

          {smallLogo?.src ? (
            <Link
              href={logo.href}
              className={styles['header__small-logo']}
              data-id={DATA_IDS.HEADER_SMALL_LOGO}
              title={logo.alt}
              aria-label={logo.alt}
            >
              <NextImage src={smallLogo.src} alt={logo.alt} fill />
            </Link>
          ) : null}
          {headerChildren}

          {!isLoading && currentUser ? (
            <div className={styles.header__user}>
              <Button
                className="details--logged-in"
                onMouseEnter={handleOnMouseEnter}
                onClick={handleOnClick}
                icon={IconVariant.User}
                size={SIZE.xLarge}
                title={membershipLabel}
                aria-label={membershipLabel}
                dataId={DATA_IDS.HEADER_USER}
              />
              <div ref={reference} className={styles['header__user-details']}>
                <MemberDetails
                  isVisible={showMemberDetails}
                  handleClose={handleClose}
                  membershipNumberLabel={
                    membershipDetails.membershipNumberLabel
                  }
                  aviosBalance={membershipDetails.aviosBalance}
                  totalAviosLabel={membershipDetails.totalAviosLabel}
                  myAccountCtaLabel={membershipDetails.myAccountCtaLabel}
                  myAccountCtaUrl={membershipDetails.myAccountCtaUrl}
                  missingAviosCtaLabel={membershipDetails.missingAviosCtaLabel}
                  missingAviosCtaUrl={membershipDetails.missingAviosCtaUrl}
                  logoutCtaLabel={membershipDetails.logoutCtaLabel}
                  logoutCtaUrl={membershipDetails.logoutCtaUrl}
                  closeDetailsButtonTitle={
                    membershipDetails.closeDetailsButtonTitle
                  }
                  showQrCodeLabel={membershipDetails.showQrCodeLabel}
                  qrCodeEnabled={membershipDetails.qrCodeEnabled}
                />
              </div>
            </div>
          ) : (
            <a
              href={`${basePath}${myAccount.href}`}
              className={styles.header__login}
              title={loginLabel}
              aria-label={loginLabel}
              onClick={() =>
                trackEvent({
                  event: EVENT_NAME.ONLINE_LOGINBUTTON,
                  user_action: USER_ACTION.LOG_IN,
                })
              }
              data-id={DATA_IDS.HEADER_LOGIN}
            >
              <Icon variant={IconVariant.User} data-id={IconVariant.User} />
            </a>
          )}
        </div>
      </div>
      <div className={styles['header__nav-container']}>
        <Nav className={styles.header__nav}>
          {navigation.static.length > 0 && (
            <Nav.Static>
              {navigation.static?.map(
                ({ destination, children, icon, isHighlighted, title }) => (
                  <Nav.Link
                    key={destination.toString()}
                    destination={destination}
                    isActive={currentUrl === destination}
                    isHighlighted={isHighlighted}
                    icon={icon}
                    title={title}
                  >
                    {children}
                  </Nav.Link>
                ),
              )}
            </Nav.Static>
          )}

          {navigation.scrollable.length > 0 && (
            <Nav.Scrollable>
              {navigation.scrollable?.map(
                ({ destination, children, icon, isHighlighted, title }) => (
                  <Nav.Link
                    key={destination.toString()}
                    destination={destination}
                    isActive={currentUrl === destination}
                    isHighlighted={isHighlighted}
                    icon={icon}
                    title={title}
                  >
                    {children}
                  </Nav.Link>
                ),
              )}
            </Nav.Scrollable>
          )}
        </Nav>
      </div>
    </header>
  );
};

export default Header;
